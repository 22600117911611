import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Jardin\u0020Infantil\u0020La\u0020Ronda',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Servicios() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='section has-background-white-bis'>
        <div className='container'>
          <div className='mb-6 columns is-multiline is-vcentered is-variable is-6'>
            <div className='column is-12 is-5-desktop'>
              <h2 className='title is-spaced'>
                <span>Servicios </span>
                <span className='has-text-primary'> Ofrecidos</span>
              </h2>
            </div>
          </div>
          <div className='columns is-multiline'>
            <div className='column is-6-tablet is-4-desktop'>
              <div className='box p-6 has-text-centered '>
                <figure className='mb-4 image'>
                  <img
                    style={{ width: '57%', height: 'auto', display: 'inline-block' }}
                    src='images/snack.svg'
                    alt=''
                  />
                </figure>
                <h3 className='title is-size-5 is-spaced mb-3'>Lonchera</h3>
              </div>
            </div>
            <div className='column is-6-tablet is-4-desktop'>
              <div className='box p-6 has-text-centered'>
                <figure className='mb-4 image'>
                  <img
                    style={{ width: '75%', height: 'auto', display: 'inline-block' }}
                    src='images/lunch.svg'
                    alt=''
                  />
                </figure>
                <h3 className='title is-size-5 is-spaced mb-3'>Almuerzo</h3>
              </div>
            </div>
            <div className='column is-12 is-4-desktop'>
              <div className='box p-6 has-text-centered'>
                <figure className='mb-4 image'>
                  <img
                    style={{ width: '100%', height: 'auto', display: 'inline-block' }}
                    src='images/bus.svg'
                    alt=''
                  />
                </figure>
                <h3 className='title is-size-5 is-spaced mb-3'>Transporte</h3>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='mb-6 columns is-multiline is-vcentered is-variable is-6'>
            <div className='column is-12 is-5-desktop'>
              <h2 className='title is-spaced'>
                <span>Horarios </span>
                <span className='has-text-primary'> Ofrecidos</span>
              </h2>
            </div>
          </div>
          <div className='columns is-multiline'>
            <div className='column is-6-tablet is-4-desktop'>
              <div className='box p-6 has-text-centered'>
                <figure className='mb-4 image'>
                  <img
                    style={{ width: '75%', height: 'auto', display: 'inline-block' }}
                    src='images/morn.svg'
                    alt=''
                  />
                </figure>
                <h3 className='title is-size-5 is-spaced mb-3'>
                  Jornada Mañana
                </h3>
                <h3 className='subtitle is-size-5 is-spaced mb-3'>
                  7:15 am a 11:50 am
                </h3>
              </div>
            </div>
            <div className='column is-6-tablet is-4-desktop'>
              <div className='box p-6 has-text-centered'>
                <figure className='mb-4 image '>
                  <img
                    style={{ width: '70%', height: 'auto', display: 'inline-block' }}
                    src='images/cole.svg'
                    alt=''
                  />
                </figure>
                <h3 className='title is-size-5 is-spaced mb-3'>
                  Jornada Tarde
                </h3>
                <h3 className='subtitle is-size-5 is-spaced mb-3'>
                  1:15 pm a 5:50 pm
                </h3>
              </div>
            </div>
            <div className='column is-12 is-4-desktop'>
              <div className='box p-6 has-text-centered'>
                <figure className='mb-4 image'>
                  <img
                    style={{ width: '100%', height: 'auto', display: 'inline-block' }}
                    src='images/read.svg'
                    alt=''
                  />
                </figure>
                <h3 className='title is-size-5 is-spaced mb-3'>
                  Jornada Completa
                </h3>
                <h3 className='subtitle is-size-5 is-spaced mb-3'>
                  7:15 am a 5:50 pm
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

