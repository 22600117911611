import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Jardin\u0020Infantil\u0020La\u0020Ronda',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Niveles() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section
        className='section'
        style={{
          backgroundImage: 'url("metis-assets/backgrounds/intersect.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='container'>
          <div className='is-max-w-md mx-auto mb-6 has-text-centered'>
            <img src='metis-assets/icons/quote.svg' alt='' />
            <h2 className='mt-4 title is-spaced'>Niveles</h2>
          </div>
          <div className='columns is-centered is-multiline'>
            <div className='column is-6 is-4-desktop'>
              <div className='box'>
                <div className='mb-4 is-flex is-align-items-center'>
                  <img
                    className='mr-3 image is-64x64 is-rounded is-object-cover'
                    src='images/1.svg'
                    alt=''
                  />
                  <div>
                    <h3 className='title is-size-5 has-text-info'>Maternal</h3>
                  </div>
                </div>
                <p className='subtitle'>
                  Nuestro equipo de trabajo orienta a los padres en el cuidado
                  del bebe , a través de divertidas experiencias que desarrollan
                  el lenguaje, fortalecen la motricidad y conocimiento de su
                  entorno.
                </p>
              </div>
            </div>
            <div className='column is-6 is-4-desktop'>
              <div className='box'>
                <div className='mb-4 is-flex is-align-items-center'>
                  <img
                    className='mr-3 image is-64x64 is-rounded is-object-cover'
                    src='images/2.svg'
                    alt=''
                  />
                  <div>
                    <h3 className='title is-size-5 has-text-info'>
                      Gateadores
                    </h3>
                  </div>
                </div>
                <p className='subtitle'>
                  Se continua con el fortalecimiento de procesos de lenguaje,
                  coordinación motora, sensorial y de socialización e
                  interacción con su entorno, estableciendo las bases para el
                  futuro.
                </p>
              </div>
            </div>
          </div>
          <div className='columns is-centered is-multiline'>
            <div className='column is-6 is-4-desktop'>
              <div className='box'>
                <div className='mb-4 is-flex is-align-items-center'>
                  <img
                    className='mr-3 image is-64x64 is-rounded is-object-cover'
                    src='images/3.svg'
                    alt=''
                  />
                  <div>
                    <h3 className='title is-size-5 has-text-info'>
                      Caminadores I &amp; II
                    </h3>
                  </div>
                </div>
                <p className='subtitle'>
                  En estos grados además de adquirir hábitos elementales para la
                  salud del cuerpo y una convivencia enriquecedora a través de
                  la interacción educadora – niño(a) y sus pares se incursiona
                  en procesos de conocimiento a través del juego.
                </p>
              </div>
            </div>
            <div className='column is-6 is-4-desktop'>
              <div className='box'>
                <div className='mb-4 is-flex is-align-items-center'>
                  <img
                    className='mr-3 image is-64x64 is-rounded is-object-cover'
                    src='images/4.svg'
                    alt=''
                  />
                  <div>
                    <h3 className='title is-size-5 has-text-info'>Párvulos</h3>
                  </div>
                </div>
                <p className='subtitle'>
                  Las actividades propuestas para éste nivel están encaminadas
                  al Desarrollo del pensamiento lógico Matemático, plástico,
                  musical y corporal mediante la adquisición de hábitos,
                  costumbres relativas a la convivencia y el fortalecimiento de
                  la conciencia de su propio cuerpo.
                </p>
              </div>
            </div>
          </div>
          <div className='columns is-centered is-multiline'>
            <div className='column is-6 is-4-desktop'>
              <div className='box'>
                <div className='mb-4 is-flex is-align-items-center'>
                  <img
                    className='mr-3 image is-64x64 is-rounded is-object-cover'
                    src='images/5.svg'
                    alt=''
                  />
                  <div>
                    <h3 className='title is-size-5 has-text-info'>Prejardín</h3>
                  </div>
                </div>
                <p className='subtitle'>
                  Las actividades en éste nivel están orientadas a profundizar
                  la coordinación ojo- mano a través de procesos grafo motrices,
                  fortalece conocimientos lógico matemáticos, adquiere nociones
                  de número y cantidad, aumenta proceso lecto escrito y control
                  del propio cuerpo.
                </p>
              </div>
            </div>
            <div className='column is-6 is-4-desktop'>
              <div className='box'>
                <div className='mb-4 is-flex is-align-items-center'>
                  <img
                    className='mr-3 image is-64x64 is-rounded is-object-cover'
                    src='images/6.svg'
                    alt=''
                  />
                  <div>
                    <h3 className='title is-size-5 has-text-info'>Jardín</h3>
                  </div>
                </div>
                <p className='subtitle'>
                  En este nivel se consolida la lengua materna, adquiere hábitos
                  de estudios, perfecciona trazos cursivos, se apropia del
                  proceso lecto escrito y lógico matemático.
                </p>
              </div>
            </div>
          </div>
          <div className='columns is-centered is-multiline'>
            <div className='column is-6 is-4-desktop'>
              <div className='box'>
                <div className='mb-4 is-flex is-align-items-center'>
                  <img
                    className='mr-3 image is-64x64 is-rounded is-object-cover'
                    src='images/7.svg'
                    alt=''
                  />
                  <div>
                    <h3 className='title is-size-5 has-text-info'>
                      Transición
                    </h3>
                  </div>
                </div>
                <p className='subtitle'>
                  En este último nivel fortalecen la lectura y escritura,
                  aumentan comprensión lectora, desarrollan a plenitud su
                  pensamiento lógico y adquieren aprendizajes más complejos,
                  gracias a todas las habilidades desarrolladas en los cursos
                  anteriores. Además tienen la opción de pertenecer a la banda
                  del jardín ejecutando un instrumento.
                </p>
              </div>
            </div>
          </div>
          <div className='py-5 has-text-centered' />
        </div>
      </section>
    </React.Fragment>
  );
}

