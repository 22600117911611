import * as React from "react";
import { BrowserRouter } from "react-router-dom";

import { createRoot } from 'react-dom/client';
import Navbar from './pages/NavBar'
import Footer from './pages/Footer'

import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
     <Navbar />
    <BrowserRouter>
            <App />
    </BrowserRouter>
    <Footer />
  </React.StrictMode>
);
