import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Jardin\u0020Infantil\u0020La\u0020Ronda',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Artistico() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section
        className='section'
        style={{
          backgroundImage: 'url("metis-assets/backgrounds/intersect.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='container'>
          <div className='is-max-w-lg mx-auto mb-6 has-text-centered'>
            <h2 className='mt-5 title is-spaced'>
              <span>Explora </span>
              <span className='has-text-primary'> nuestra </span>
              <span> área de arte.</span>
            </h2>
          </div>
          <div className='is-relative'>
            <div className='is-absolute is-fullwidth is-hidden-touch'>
              <div className='has-border-y py-1' />
            </div>
            <div className='columns'>
              <div className='column is-3 mb-5 has-text-centered'>
                <div>
                  <figure className='is-4by3 image'>
                    <img
                      src='images/ratonBateria.svg'
                      alt=''
                    />
                  </figure>
                </div>
                <p className='title'>Música</p>
                <p className='subtitle'>
                  Desde su inicio el Jardín Infantil La Ronda, convencido de
                  brindar una educación para la vida, decide incluir en su
                  proyecto educativo la enseñanza de la música, donde fusiona la
                  sensibilidad y solidaridad con el desarrollo de todas las
                  habilidades cognitivas y corporales, proporcionando mejores
                  condiciones sociales para sus alumnos.
                </p>
              </div>
              <div className='column is-3 mb-5 has-text-centered'>
                <div>
                  <figure className='mb-4 image'>
                    <img
                      src='images/teatro.svg'
                      alt=''
                    />
                  </figure>
                </div>
                <p className='title'>Teatro</p>
                <p className='subtitle'>
                  Con actividades divertidas y educativas los pequeños mejoran
                  su capacidad de concentración, atención y memoria fomentando
                  tambien la imaginación, el desarrollo de la creatividad asi
                  como el uso de sus sentidos y enriqueciendo la auto confianza
                  como la parte social.
                </p>
              </div>
              <div className='column is-3 mb-5 has-text-centered'>
                <div>
                  <figure className='mb-4 image'>
                    <img
                      src='images/danza.svg'
                      alt=''
                    />
                  </figure>
                </div>
                <p className='title'>Danza</p>
                <p className='subtitle'>
                  Esta disciplina es realmente un reto a la musicalidad y
                  coordinación de los pequeños, ayudándolos a mejorar
                  ampliamente su desarrollo motriz. Disfrutar de la música
                  apoyado con diferentes tipos de movimientos acorde con los
                  diferentes géneros permite que el aprendizaje del niño sea
                  enriquecido.
                </p>
              </div>
              <div className='column is-3 mb-5 has-text-centered'>
                <div>
                  <figure className='mb-4 image'>
                    <img
                      src='images/ingles.svg'
                      alt=''
                    />
                  </figure>
                </div>
                <p className='title'>English</p>
                <p className='subtitle'>
                  We employ a comprehensive methodology tailored to children,
                  emphasizing student motivation by engaging them through
                  playful activities such as games, songs, stories, and
                  interactive experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

