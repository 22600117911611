import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Jardin\u0020Infantil\u0020La\u0020Ronda',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section>
          <div
            className='hero is-fullheight custom-background is-background-no-repeat'
            style={{
              backgroundImage:
                'url("images/landronda.png")',
              backgroundPosition: 'top'
            }}
          >
            <div className='section' contentEditable='false'>
              <div className='container'>
                <div
                  className='is-max-w-lg mx-auto pb-6 has-text-centered'
                  contentEditable='false'
                >
                  <h2 className='title is-spaced has-text-white'>
                    <span contentEditable='false'>Alegría </span>
                    <span className='has-text-primary' contentEditable='false'>
                       sin fin! 
                    </span>
                  </h2>
                  <p
                    className='title has-text-white'
                    contentEditable='false'
                  >
                    <span contentEditable='true'>Desde 1981 </span>
                    <span> Abrazando la ternura!!! </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='hero is-primary'>
          <div className='hero-body has-text-white'>
            <div className='container'>
              <div className='is-max-w-lg mx-auto has-text-centered'>
                <h2 className='mt-5 title is-spaced' contentEditable='false'>
                  <a href='historia'>Nuestra Historia</a>
                </h2>
                <div className='mt-5' />
              </div>
            </div>
          </div>
        </section>
        
      </>
    </React.Fragment>
  );
}

