import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Jardin\u0020Infantil\u0020La\u0020Ronda',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Academico() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section
        className='section'
        style={{
          backgroundImage: 'url("metis-assets/backgrounds/intersect.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='container'>
          <h2 className='title has-text-info mb-6'>Academico</h2>
          <div className='mb-6 columns is-desktop is-vcentered is-variable is-6 is-flex-dir-col-reverse-touch is-flex-dir-row-desktop'>
            <div className='column' />
          </div>
          <div className='mb-6 columns is-desktop is-vcentered is-variable is-6 is-flex-dir-col-reverse-touch is-flex-dir-row-reverse-desktop'>
            <div className='column'>
              <h3 className='title is-spaced has-text-info'>Misión</h3>
              <p className='subtitle' contentEditable='false'>
                La institución Jardín Infantil “ La Ronda ” es una entidad
                Educativa dedicada a formar integralmente al niño y niña en edad
                preescolar: Sala cuna, Pre jardín, Jardín y Transición. Orienta
                sus conocimientos hacia el avance personal, social, tecnológico
                y cultural de su medio, a través de experiencias lúdicas,
                artísticas y creativas, donde prima la sensibilidad y
                experimentación con su entorno, en un clima de afecto, libertad
                y sociabilidad incluyendo a la familia como base fundamental en
                su proceso educativo.
              </p>
            </div>
            <div className='column'>
              <div className='column is-6-fullhd is-12-mobile py-6 is-flex'>
                  <figure>
                    <img
                      style={{ width: '100%', height: 'auto', display: 'inline-block' }}
                      src='images/ratonMision.svg'
                      alt=''
                    />
                  </figure>
              </div>
            </div>
          </div>
          <div className='mb-6 columns is-desktop is-vcentered is-variable is-6 is-flex-dir-col-reverse-touch is-flex-dir-row-desktop'>
            <div className='column'>
              <h3 className='title is-spaced has-text-info'>Visión</h3>
              <p className='subtitle' contentEditable='false'>
                Nuestra labor pedagógica se fundamenta en consolidar las bases
                para un adecuado desarrollo cognitivo, afectivo, social y
                corporal, que proyecta generar un niño y niña con procesos
                particulares y colectivos de reconocimiento, de identidad,
                autonomía, respeto, tolerancia, cooperación y convivencia, a
                favor de su sensibilidad, creatividad y participación en su
                proceso de formación, teniendo en cuenta las características
                individuales y el grado en que se desenvuelve..
              </p>
            </div>
            <div className='column'>
              <div className='column is-6-fullhd is-12-mobile py-6 is-flex'>
                    <figure>
                      <img
                        style={{ width: '100%', height: 'auto' }}
                        src='images/vision.svg'
                        alt=''
                      />
                    </figure>
                </div>
            </div>
          </div>
          <div className='mb-6 columns is-desktop is-vcentered is-variable is-6 is-flex-dir-col-reverse-touch is-flex-dir-row-reverse-desktop'>
            <div className='column'>
              <h3 className='title is-spaced has-text-info' contentEditable='false'>
                Filosofía
              </h3>
              <p className='subtitle'>
                El enfoque educativo que orienta la formación integral del niño
                y niña del Jardín, se fundamenta en la PERSONA; por lo cual el
                propósito central de la labor pedagógica es llevarlos a que sean
                participativos, tolerantes, críticos, responsables,
                cuestionadores de la realidad, investigadores del saber
                científico- técnico y artístico, donde prime el aprendizaje
                compartido y socializador. La Ronda garantiza brindar un
                ambiente propicio para el desarrollo cognitivo, físico, personal
                y social del niño, a través de:
              </p>
            </div>
            <div className='column'>
              <div className='column is-6-fullhd is-12-mobile py-6 is-flex '>
                    <figure>
                      <img
                        style={{ width: '100%', height: 'auto' }}
                        src='images/RatonMama.svg'
                        alt=''
                      />
                    </figure>
              </div>
            </div>
          </div>
          <div className='has-text-centered' />
        </div>
      </section>
    </React.Fragment>
  );
}

