import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Jardin\u0020Infantil\u0020La\u0020Ronda',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Expresion() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section
        className='section'
        style={{
          backgroundImage: 'url("metis-assets/backgrounds/intersect.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='container'>
          <div className='is-max-w-lg mx-auto mb-6 has-text-centered'>
            <h2 className='mt-5 title is-spaced'>
              <span>Explora </span>
              <span className='has-text-primary'> nuestra </span>
              <span> área de expresión corporal.</span>
            </h2>
          </div>
          <div className='is-relative'>
            <div className='is-absolute is-fullwidth is-hidden-touch'>
              <div className='has-border-y py-1' />
            </div>
            <div className='columns'>
              <div className='column is-3 mb-5 has-text-centered'>
                <div>
                  <figure className='mb-4 image'>
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      src='images/patinaje.svg'
                      alt=''
                    />
                  </figure>
                </div>
                <p className='title'>Patinaje</p>
                <p className='subtitle'>
                  Esta disciplina deportiva es una alternativa de recreación,
                  donde los niños y niñas aprovechan del tiempo libre,
                  desarrollan el equilibrio, ya que requiere de control preciso
                  y dominio de parámetros durante el aprendizaje y máxima
                  concentración en las diferentes situaciones de deslizamiento o
                  desplazamientos. Además, permite que el niño y niña controle
                  sus movimientos e impulsos cuidando su cuerpo y el de los
                  demás. De acuerdo a la edad se programan ejercicios que lo
                  lleven al disfrute y fortalecimiento de sus músculos hasta
                  lograr soltura y manejo de los patines con gran rapidez.
                </p>
              </div>
              <div className='column is-3 mb-5 has-text-centered'>
                <div>
                  <figure className='mb-4 image'>
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      src='images/natacion.svg'
                      alt=''
                    />
                  </figure>
                </div>
                <p className='title'>Natación</p>
                <p className='subtitle'>
                  En el jardín infantil ¨La Ronda¨ la natación es un medio
                  educativo y formativo para crear las bases técnicas, tácticas,
                  metodológicas y psicológicas del deporte escolar en los niños,
                  a la vez que le permite descubrir y manifestar sus habilidades
                  motrices y mentales. En los niveles de salacuna se les hace un
                  acercamiento a ésta disciplina a través de juegos dentro de la
                  piscina y ejercicios para soportar el agua en la cara.
                  Posteriormente, en los niveles mayores se establecen
                  ejercicios para respirar bajo el agua, flotar y desplazarse.
                  La Natación conserva en su práctica el desarrollo óptimo de
                  capacidades y funciones del organismo, la alegría y
                  satisfacción por su práctica y la exaltación de valores y
                  actitudes positivas hacia el equipo, las reglas y hacia uno
                  mismo.
                </p>
              </div>
              <div className='column is-3 mb-5 has-text-centered'>
                <div>
                  <figure className='mb-4 image'>
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      src='images/zancos.svg'
                      alt=''
                    />
                  </figure>
                </div>
                <p className='title'>Zancos</p>
                <p className='subtitle'>
                  Esta actividad se justifica por la motivación que supone andar
                  en zancos para los niños y niñas de 'La Ronda', por la
                  facilidad y rapidez con la que se efectúa el aprendizaje,
                  permitiendo que el miedo inicial se transforme en una profunda
                  confianza en sí mismo. También se establecen mejoras en
                  aspectos como coordinación, equilibrio y ritmo, y la
                  modificación de la forma de desplazarse, que supone la
                  resolución constante por parte del niño y niña de pequeños
                  problemas motrices. La postura de zancos colabora además para
                  adquirir independencia y aumentar destrezas manuales. Esta
                  actividad inicia con los niños en el nivel de jardín y
                  transición.
                </p>
              </div>
              <div className='column is-3 mb-5 has-text-centered'>
                <div>
                  <figure className='mb-4 image'>
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      src='images/acrobacia.svg'
                      alt=''
                    />
                  </figure>
                </div>
                <p className='title'>Acrobacia Aérea y Piso</p>
                <p className='subtitle'>
                  Esta actividad permite a los niños y niñas aprender y valorar,
                  las posibilidades de su cuerpo, y el de los demás. También es
                  la oportunidad muy original para trabajar creatividad,
                  equilibrio, flexibilidad, fuerza, hábitos de higiene o de
                  alimentación. Con la acrobacia de piso y áerea los niños
                  disfrutan y fortalecen la confianza en si mismo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

