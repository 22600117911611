import React, {useState} from 'react';

function Navbar() {
  const [isActive, setIsActive] = useState(false);

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };
  return (
    <section>
    <nav
      className='navbar is-relative is-transparent py-3'
      role = "navigation"
      aria-label='main navigation'
    >
      <div className='container'>
        <div className='navbar-brand'>
          <a className='navbar-item' href='/'>
            <img
              src='images/favicon.ico'
              alt=''
              width='auto'
              style={{ minHeight: 40 }}
            />
          </a>
          <a
            className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
            role='button'
            aria-expanded='false'
            aria-label='menu'
            onClick={toggleNavbar}
          >
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            <span aria-hidden='true' />
          </a>
        </div>
        <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
          <div className='navbar-start'>
            <a className='navbar-item' href='/admisiones'>
              Admisiones
            </a>
            <a className='navbar-item' href='academico'>
              Académico
            </a>
            <a className='navbar-item' href='artistico'>
              Artístico
            </a>
            <a className='navbar-item' href='expresion'>
              Expresión Corporal
            </a>

            <a className='navbar-item' href='niveles'>
              Niveles
            </a>
            <a className='navbar-item' href='servicios'>
              Servicios
            </a>
          </div>
        </div>
      </div>
    </nav>

  
    <div class="is-hidden navbar-side is-fixed is-centered-absolute">
       
      <div class="navbar-backdrop is-fixed is-top is-right is-fullsize has-background-grey">
      </div>
    <nav class="navbar is-relative is-flex is-flex-direction-column pt-4 px-4"> 
      <div class="mb-5 navbar-brand is-flex is-justify-content-space-between">
        <a class="navbar-item" href="/">
        <img
              src='images/favicon.ico'
              alt=''
              width='auto'
              style={{ minHeight: 35 }}
            />
        </a>
      </div>
      <div>
        <nav class="navbar mb-4">
          <div class="is-fullwidth">
          <a className='navbar-item' href='admisiones'>
              Admisiones
            </a>
            <a className='navbar-item' href='academico'>
              Académico
            </a>
            <a className='navbar-item' href='artistico'>
              Artístico
            </a>
            <a className='navbar-item' href='expresion'>
              Expresión Corporal
            </a>
            <a className='navbar-item' href='niveles'>
              Niveles
            </a>
            <a className='navbar-item' href='servicios'>
              Servicios
            </a>
            </div>
        </nav>
      </div>
   
    </nav>
  </div>
    
  </section>
  );
};

export default Navbar;