import React from 'react';
import {
  Routes,
  Route 
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import AdmisionesPage from './pages/Admisiones.js';
import AcademicoPage from './pages/Academico.js';
import ArtisticoPage from './pages/Artistico.js';
import ExpresionPage from './pages/Expresion.js';
import NivelesPage from './pages/Niveles.js';
import ServiciosPage from './pages/Servicios.js';
import IssuesPage from './pages/Issues.js';
import HistoriaPage from './pages/Historia.js';



function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/admisiones" element={<AdmisionesPage />} />
      <Route path="/academico" element={<AcademicoPage />} />
      <Route path="/artistico" element={<ArtisticoPage />} />
      <Route path="/expresion" element={<ExpresionPage />} />
      <Route path="/niveles" element={<NivelesPage />} />
      <Route path="/servicios" element={<ServiciosPage />} />
      <Route path="/issues" element={<IssuesPage />} />
      <Route path="/historia" element={<HistoriaPage />} />
    </Routes>
    
  );
}

export default App;
