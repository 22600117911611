import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Jardin\u0020Infantil\u0020La\u0020Ronda',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Admisiones() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section
          
        >
          <div className='columns'>
            <div className='column is-5-fullhd is-12-mobile py-6 is-flex is-justify-content-right'>
                <figure className='is-max-w-xs'>
                  <img
                    style={{ width: '80%', height: 'auto', display: 'inline-block' }}
                    src='images/sol.svg'
                    alt=''
                  />
                </figure>
            </div>
            <div className='column is-5-fullhd is-12-mobile py-6 is-flex is-justify-content-center'>
              <div className='container px-6 mx-auto'>
                <h3 className='title has-text-primary' contentEditable='false'>
                  Documentos
                </h3>
                <ul>
                  <li className='mb-3'>
                    <span contentEditable='false'>Registro Civil Original</span>
                  </li>
                  <li className='mb-3'>
                    <span contentEditable='false'>Constancia Retiro Simat</span>
                  </li>
                  <li className='mb-3'>
                    <span contentEditable='false'>
                      Paz y Salvo Final del Colegio Donde Viene
                    </span>
                  </li>
                  <li className='mb-3'>
                    <span contentEditable='false'>
                      Certificado de Conducta y/o Observador
                    </span>
                  </li>
                  <li className='mb-3'>
                    <span contentEditable='false'>Certificado Médico</span>
                  </li>
                  <li className='mb-3'>
                    <span contentEditable='false'>Observador</span>
                  </li>
                  <li className='mb-3'>
                    <span contentEditable='false'>Carnet de Vacunas</span>
                  </li>
                  <li className='mb-3'>
                    <span contentEditable='false'>
                      Copia Cedula y RUT del acuediente
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className='section'>
          <div className='container'>
            <div className='mb-6 columns is-desktop is-multiline'>
              <div className='column is-6-desktop'>
                <div
                  className='p-6 has-background-light has-text-centered is-radius'
                  contentEditable='false'
                >
                  <p className='subtitle' contentEditable='true'>
                    Los invitamos a ser parte de la familia Ronda y vivir ésta
                    fantástica experiencia, donde los pequeños puedan vernos
                    como una casa que les da seguridad, confianza, que les
                    ofrece la satisfacción de sus necesidades, que les estimula,
                    en definitiva, una casa donde desarrollarse y crecer
                    felices.
                  </p>
                </div>
              </div>
              <div className='column is-6-desktop'>
                <div className='p-6 has-background-light has-text-centered is-radius'>
                  <p className='subtitle'>
                    Desde 1981 el jardín infantil La Ronda abraza la ternura con
                    la experiencia de profesionales en Educación infantil en
                    cada curso, respetando los niveles y ritmos de aprendizaje,
                    a través de sencillos proyectos de aula como estrategias
                    pedagógicas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

