import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Jardin\u0020Infantil\u0020La\u0020Ronda',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Issues() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='section'>
        <div className='container'>
          <div className='is-max-w-md mx-auto has-text-centered'>
            <img
              className='mb-5 is-fullwidth p-4'
              src='metis-assets/illustrations/error2.png'
              alt=''
            />
            <span className='title has-text-primary'>Whoops!</span>
            <h2 className='title is-spaced'>Algo no funciono!</h2>
            <p className='subtitle'>
              Mil disculpas, pero parece algo no esta funcionando bien.
            </p>
            <div className='buttons is-centered'>
              <a className='button is-primary' href='/'>
                Cargar
              </a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

