import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Jardin\u0020Infantil\u0020La\u0020Ronda',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Historia() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='section'>
        <div className='container'>
          
          <div className='is-max-w-2xl mx-auto mb-6'>
            <div className='has-text-centered mb-5'>
              <h2 className='mt-4 title has-text-primary'>Reseña Histórica</h2>
            </div>
          </div>
          <div className='mb-4 image'>
            <img
              className='is-h-100 is-fullwidth is-object-cover is-radius mb-4 has-text-centered'
              src='images/history.svg'
              alt=''
            />
          </div>
          <div className='is-max-w-2xl mx-auto'>
            <p className='subtitle'>
              Mil pétalos de flor empezaron a juntarse, un sueño de amor se
              estaba inventando. Para todos los que estábamos allí y que hoy aun
              creemos en una sonrisa, en la amistad., el llanto sin lágrimas,
              los errores, la oportunidad, un arco iris en plastilina, las
              buenas costumbres, en Diosito y lo que hay después de la curva en
              el camino, sabíamos que este sueño sería una realidad. Hoy treinta
              y seis años más tarde estamos felices, seguros que este esfuerzo
              ha valido la pena. Las semillitas corren por todas partes y
              florecen una y otra vez sentimientos de amor con cualquier
              palabra, gesto o travesura.
            </p>
            <p className='subtitle'>
              El deseo de formar una casa, una familia que sintiera el amor
              sincero por el prójimo, con desinterés y rodeada del mejor ánimo
              para crecer y formarse con una educación basada en los valores
              humanos, fue el punto de partida para que, en 1981 Ilva Rosa Gaona
              de Abril creara en nuestra ciudad una nueva institución educativa
              a nivel preescolar, la que bautizó con el nombre Jardín Infantil
              “La Ronda”.
            </p>
            <p className='subtitle'>
              Estos primeros cuatro añitos se vivieron a plenitud y todas las
              singulares, comunes, poco comunes, y disparatadas necesidades
              infantiles, fueron atendidas dentro del mejor ambiente para el
              desarrollo integral de todos los chicos.
            </p>
            <p className='subtitle'>
              Con la llegada de 1985 La Ronda tomó un nuevo curso, una segunda
              etapa.
            </p>
            <p className='subtitle'>
              La dirección educativa quedó a cargo de Gloria Inés Bastidas,
              quien puso en práctica nuevos conceptos que ese entonces
              presentaba la educación infantil. Se propuso como objetivo
              principal desarrollar, en el niño un equilibrio cognitivo: a – b-2
              -3 – c; motriz: abajo – arriba – a través; del lenguaje: chis –
              tras – plum; y socio afectivo: t.q.m!
            </p>
            <p className='subtitle'>
              Y esa tarea fue aún más complicada: dividir los niños por niveles,
              planear clases, nombrar nuevos profesores, comprar una tiza,
              implementar nuevas canciones y juegos, amarrar más zapatos, tener
              menos tiempo, subir y bajar escaleras cada minuto, conocer con
              exactitud el repique del teléfono y hablar con un número mayor de
              personas. Sin embargo, todos los corazones estaban dichosos por el
              adelanto de los alumnos, por su alegría y la cariñosa dedicación
              de padres y maestros. La satisfacción por el trabajo fue total.
            </p>
            <p className='subtitle'>
              Este sueño, como todo buen sueño no merecía tener final debería
              ser para toda la vida. Entonces, la magia de la ternura de nuevo
              se presentó. Pero en esta ocasión, sin intención, como lo son los
              buenos instantes de la vida, lo hizo por duplicado. Fue así como
              1991 Sonia Judith y Gloria Mabel Suarez Cordero asumieron el reto
              de continuar con la misión de educar y formar integralmente a los
              alumnos de la Ronda, con amor, dedicación y disciplina.
            </p>
            <p className='subtitle'>
              Fue una época de nuevos retos y las cosas, las importantes cosas,
              desde la más pequeña hasta la más grande, cambiaron notablemente.
              Desde ese año se ha vivido con alegría e intensidad; en cada
              momento se evidencia la sensibilidad, la perseverancia, la pasión
              por la enseñanza, se disfruta de una tímida mirada, de un tierno
              llanto, de una lonchera olvidada, de las palabras a medias, los
              ordenados, los desordenados, impecables, manchados y coloridos
              cuadernos, las risas, los juegos, el silencio y las diez mil
              pilatunas de todos los días
            </p>
            <p className='subtitle'>
              A la “profe directora” le dio por reformar el pensum
              actualizándolo con asignaturas que hasta el momento le ha
              permitido alcanzar nuevas metas para el desarrollo de la
              educación, ubicando el jardín dentro de las mejores instituciones
              preescolares de la ciudad.
            </p>
            <p className='subtitle'>
              Entonces los computadores, el teatro, la danza, la acrobacia, los
              zancos, el inglés, la música y los deportes entraron como nuevos
              elementos para abonar este gran árbol de educación, que, junto a
              la constante capacitación de sus profesores, la motivación por el
              trabajo y la dedicación a los niños, nos ha permitido respirar ese
              abundante amor que recoge debajo de él. Y como todo buen amigo,
              conoce nuestra tierra, nos da sombra, nos protege y ofrece su
              fruto.
            </p>
            <p className='subtitle'>
              Como resultado de nuestro gran empeño nos aventuramos a cobijar
              niños y niñas menores de 36 meses , La Salacuna , una mini Ronda
              que abre sus brazos cálidos llenos de ternura y que sigue los
              pasos de excelencia del Jardín Infantil La Ronda. Allí tenemos las
              mejores instalaciones para la atención de todos los pequeñines,
              que nos impulsa día a día a ser más sensibles, más artistas, más
              compañeros y mejores amigos.
            </p>
            <p className='subtitle'>
              Estos han sido muy buenos años para todos; hemos trabajado duro
              por entregar y recibir riqueza espiritual, para enaltecer la
              familia, para enseñar y aprender, y poder decir que cristalizamos
              nuestros anhelos de felicidad compartiéndolo con los demás. Los
              primeros treinta y seis Años son el camino seguro para seguir
              trabajando y seguir creciendo no como un objetivo, sino como un
              proceso que durara toda la vida.
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

